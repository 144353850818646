import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/VideoBackgroundComponent.css';
import Logo from '../images/elevateher_logo_black.png';
import BluePurpleCirle from '../images/blue_and_purple_circle.png';
import WhiteYellowCirle from '../images/white_and_yellow_circle.png';
import BlueCircleDots from '../images/blue_circle_dots.png';

const VideoBackgroundComponent = () => {
  return (
    <>
      <div className="video-background">
        <img src={BluePurpleCirle} alt='' className='b-p-circle'/>
        <img src={WhiteYellowCirle} alt='' className='w-y-circle'/>
        <img src={BlueCircleDots} alt='' className='b-circle-dots'/>
        <div className="content">
          <div className='column align-center landing-page-main'>
            <img src={Logo} alt='logo' className='main-logo' />
            <p className='bold size3 main-desc'>Training women to become Full-stack Developers and linking them with international remote jobs</p>
            <Link to="/dashboard" className='get-started'>Get Started</Link>
          </div>
        </div>
      </div>
      <div className='column stripes'>
        <div className='row first-stripes'>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='row second-stripes'>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className='row third-stripes'>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default VideoBackgroundComponent;
