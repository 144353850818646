import React, { useState, useEffect } from 'react';
import EligibilityImage from '../images/eligibility.jpg';
import CalendarImage from '../images/calendar.jpg';
import LanguagesImage from '../images/languages.jpg';
import CurriculumImage from '../images/curriculum.jpg';
import Tick from '../images/white-tick.png';
import HTML from '../images/html.png';
import CSS from '../images/css.png';
import GitHub from '../images/github.png';
import Git from '../images/git.png';
import JavaScript from '../images/js.png';
import ReactLogo from '../images/react.png';
import Node from '../images/node.png';
// import Ruby from '../images/ruby.png';
// import Rails from '../images/rails.png';
import Mongodb from '../images/mongodb.svg';
// import Mysql from '../images/mysql.png';
// import ReduxLogo from '../images/redux.png';
import '../styles/Categories.css';

const Categories = () => {
  const ToolsData = [
    { id: 1, name: 'HTML', image: `${HTML}` },
    { id: 2, name: 'CSS', image: `${CSS}` },
    { id: 3, name: 'GitHub', image: `${GitHub}` },
    { id: 4, name: 'Git', image: `${Git}` },
    { id: 5, name: 'JavaScript', image: `${JavaScript}` },
    { id: 6, name: 'React', image: `${ReactLogo}` },
    { id: 7, name: 'MongoDB', image: `${Mongodb}`, style: 'tool-logo-special' },
    { id: 8, name: 'Node', image: `${Node}`},
    // { id: 7, name: 'Redux', image: `${ReduxLogo}`, style: 'tool-logo-special' },
    // { id: 9, name: 'MySQL', image: `${Mysql}` },
    // { id: 10, name: 'Ruby', image: `${Ruby}` },
    // { id: 11, name: 'Rails', image: `${Rails}` },
  ];

  const categories = [
    { title: 'Eligibility', className: 'eligibility', image: EligibilityImage, popupComponent: 
    <>
      <div className='eligibility-contents'>
        <p className='white bold'>The following are the criteria for eligibility. Every prospective student MUST:</p>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Be a female</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Be 18 years or above</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Be able to devote to at least 5 hours of coding every day</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Have a functional computer with a good microphone, webcam, and at least 4 GB RAM and 256 GB space of HDD or SSD</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Produce 2 qualified guarantors</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Agree to our terms and conditions</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Pass our screening process</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Be dedicated and committed</p>
        </div>
      </div>
    </> 
  },
    { title: 'Calendar for 2024', className: 'calendar', image: CalendarImage, popupComponent: 
    <>
      <div className='eligibility-contents'>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Cohort 1 - 24 June, 2024</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Cohort 2 - 19 August, 2024</p>
        </div>
        {/* <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Cohort 3 - 20 August, 2024</p>
        </div> */}
      </div>
    </>
    },
    { title: 'Languages & Frameworks', className: 'languages', image: LanguagesImage, popupComponent: 
    <>
      <div className='tools-section'>
        <div className='popup-tools'>
          {ToolsData.map((tool, index) => (
            <div key={index} className='column align-center'>
              <img src={tool.image} alt='' className={`tool-logo ${tool.style}`}/>
              <p className='tool-name white'>{tool.name}</p>
            </div>
          ))}
        </div> 
      </div>
    </>
    },
    { title: 'Curriculum', className: 'curriculum', image: CurriculumImage, popupComponent: 
    <>
      <div className='eligibility-contents'>
        <p className='white bold'>FRONT END</p>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Module 0 - Skill UP</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Module 1 - HTML & CSS</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Module 2 - JavaScript</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Module 3 - React</p>
        </div>
        <p className='white bold'>BACK END</p>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Module 4 - Node/MongoDB</p>
        </div>
        <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Module 5 - Final Project/Job Search</p>
        </div>
        {/* <div className='popup-list'>
          <img src={Tick} className='list-tick' alt='' />
          <p>Module 6 - Final Project</p>
        </div> */}
      </div>
    </>
    },
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [popupName, setPopupName] = useState('');
  const [popupImage, setPopupImage] = useState('');
  const [popupComponent, setPopupComponent] = useState('');

  const openPopup = (title, image, component) => {
    setPopupName(title);
    setPopupImage(image);
    setPopupComponent(component);
    setShowPopup(true);
  };

  const closePopup = () => {
    setPopupName('');
    setPopupImage('');
    setPopupComponent('');
    setShowPopup(false);
  };

  useEffect(() => {
    if (showPopup) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }

    return () => {
      document.body.classList.remove('body-no-scroll');
    };
  }, [showPopup]);

  return (
    <>
      <div>
        <div className='categories'>
        {categories.map((category, index) => (
          <div key={index}>
            <div>
              <h4 className='section-title section-title-desk'>{category.title}</h4>
              <div className='rule'></div>
            </div>

            <div className='column align-center'>
              <div
                className={`category ${category.className}`}
                style={{ backgroundImage: `url(${category.image})` }}
              >
                <button className='white' onClick={() => openPopup(category.title, category.image, category.popupComponent)}>See more</button>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content" style={{ backgroundImage: `url(${popupImage})` }}>
            <i className="fa-regular fa-circle-xmark popup-close" onClick={closePopup}></i>
            <div>
              <h3 className='animate__animated animate__fadeInUp popup-heading'>{popupName}</h3>
            </div>
            <div className='animate__animated animate__fadeInUp popup-component'>
              {popupComponent}
            </div>
            <div className='animate__animated animate__fadeInUp more-info'>
              <p className='bold'>For inquiry or more information,</p>
              <p>Call: +234 901 696 6819 or</p>
              <p>Email: admission@elevateher.com.ng</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Categories;
