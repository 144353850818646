import React from 'react';
import '../styles/Handbook.css';

const Handbook = () => {
  const handbookSections = [
    {
      title: 'Expected Behavior',
      content: 'The following are some of the requirements of every student:',
      listItems: [
        'Be supportive of fellow students, both proactively and responsively',
        'Use inclusive language, and strive to see the person first before their gender, religion, culture, or race.',
        'Be collaborative.',
        'Be generous in both giving and accepting feedback.',
        'Express empathy towards fellow students.',
        'Be kind.',
      ],
    },
    {
      "title": "Module Repetition",
      "content": "There are a few reasons why you may be required to repeat a module in the program:",
      "listItems": [
        "Taking a leave of absence due to personal or financial circumstances.",
        "Failure to meet a deadline within the module.",
        "NOTE: Students are allowed to repeat a module only once."
      ]
    },
    {
      "title": "Leave of Absence",
      "content": "Any student who wishes to take a leave of absence should note the following:",
      "listItems": [
        "Send an email to admission@elevateher.com.ng, clearly stating your reason for requesting a leave of absence.",
        "In the email, include your expected return date.",
        "To notify the admissions team of your return, send an email to admission@elevateher.com.ng.",
        "Your return will be subject to the availability of a cohort at the same module you left.",
        "NOTE: Upon approval of your leave request, you will lose access to the ElevateHER Students workspace on Slack and the online study materials on GitHub. You will be re-onboarded upon your return."
      ]
    }
  ];

  return (
    <>
      <div className='dashboard-component handbook-back'>
        <div className="dashboard-overlay"></div>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>Handbook</p>
        </div>

        <div style={{backgroundColor: "#f3f3fb", paddingBottom: "40px"}}>
          {handbookSections.map((section, index) => (
            <div key={index} className='handbook-items'>
              <div className='info-item'>
                <span className='purple bold size5'>{section.title}</span><br />
                <span className='size4'>
                  <p className='component-text' style={{textAlign: "left"}}>{section.content}</p>
                  <ul className='component-text handbook-list'>
                    {section.listItems.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </span>            
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Handbook;
