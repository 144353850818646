import React from 'react';
import '../styles/LandingPage.css';
import VideoBackgroundComponent from '../components/VideoBackgroundComponent';
import VisionMission from '../components/VisionMission';
import Programs from '../components/Programs';
import Categories from '../components/Categories';
import Navbar1 from '../components/Navbar1';
import Footer from '../components/Footer';
import ScrollToTopButton from '../components/ScrollToTopButton';

const LandingPage = () => {

  return (
    <>
      <Navbar1 />
      <VideoBackgroundComponent />
      <div className='landing-page-main'>
        <VisionMission />
        <Programs />
        <Categories />
      </div>
      <ScrollToTopButton />
      <Footer />
    </>
  );
};

export default LandingPage;
