// import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';

// // Your web app's Firebase configuration
// const config = {
//     apiKey: "AIzaSyBSIJQZYN0vi7nHNxvy3wV-wsOUpoArxwI",
//     authDomain: "elevateher-20289.firebaseapp.com",
//     projectId: "elevateher-20289",
//     storageBucket: "elevateher-20289.appspot.com",
//     messagingSenderId: "972347010178",
//     appId: "1:972347010178:web:84ac4c536694a9e664189b"
// };
  
// // Initialize Firebase
// firebase.initializeApp(config);

// firebase.firestore().settings({ timestampsInSnapshots: true });

// export default firebase 



import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyBSIJQZYN0vi7nHNxvy3wV-wsOUpoArxwI",
  authDomain: "elevateher-20289.firebaseapp.com",
  projectId: "elevateher-20289",
  storageBucket: "elevateher-20289.appspot.com",
  messagingSenderId: "972347010178",
  appId: "1:972347010178:web:84ac4c536694a9e664189b"
};
  
// Initialize Firebase
const firebaseApp = initializeApp(config);

// Get Firestore and Auth instances
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
