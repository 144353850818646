import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

const ApplyRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://forms.gle/kQLJeskDq1BVDLu19';
  }, []);

  return null;
};

export default ApplyRedirect;