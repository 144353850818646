import React from 'react';
import { Link } from 'react-router-dom';

const Meeting = ({ meetings }) => {
  return (
    <>
      <div className='dashboard-component meeting-back'>
        <div className="dashboard-overlay"></div>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>Meeting</p>
        </div>

        <div>
          <p className='info-item'>
            <span className='purple bold size5'>Meeting Etiquettes</span><br />
            <span className='size4'>Please, click on the link below to join the meeting on Google Meet.<br /><br />Please, remember to put your camera on and also keep your microphone muted until you are asked to speak.<br /><br />Also remember to stay in a quiet and well-lit space with a plain background.</span>            
          </p>

          {meetings.map(meeting => (
            <div key={meeting.id}>
              <p className='info-item' style={{marginTop: "0px"}}>
                <span className='purple bold size5'>Date</span><br />
                <span className='size4'>{meeting.date}</span>            
              </p>
              <p className='info-item' style={{marginBottom: "40px"}}>
                <span className='purple bold size5'>Time</span><br />
                <span className='size4'>{meeting.time}</span>            
              </p>
              <Link to={meeting.link} target='_blank' className='dashboard-component-btn'>
                Join
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Meeting;
