import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/FaqsPage.css';

const FaqsPage = () => {

  const faqsList = [
    {
      question: 'When will applications open?',
      answer: 'Applications to ElevateHER will open when the Full-Stack Web Development Program launches in 2024. Exact dates are not available at this time.',
    },
    {
      question: 'How do I join the waitlist?',
      answer: "You have to Register using the 'Join Us' form. Eligible candidates will be shortlisted and contacted based on some criteria.",
    },
    {
      question: 'How much do I pay for registration into the program?',
      answer: 'The program is absolutely FREE. You do not have to pay to register. All you need to do is meet all the requirements needed to get enrolled into the program.',
    },
    {
      question: 'What are the requirements needed to get enrolled into the program?',
      answer: "Please, check the 'Eligibility' section on the main page for information.",
    },
    {
      question: 'How long will the program last?',
      answer: "Please, check the 'Curriculum' section on the main page for information.",
    },
    {
      question: 'What programming languages and frameworks will be taught in the program?',
      answer: "Please, check the 'Languages & Frameworks' section on the main page for information.",
    },
    {
      question: 'Is the program remote or on-site?',
      answer: "The choice is the student's. We have a school with 24 hours power supply and free internet services. Students can choose to attend program on-site, hybrid or fully remote.",
    },
    {
      question: "What do I do if I don't find an answer to the question I have in mind in the FAQs page?",
      answer: "Please, use the 'Contact Us' form to reach out to one of our support staff and you will get a response in no less than 24 HOURS.",
    },
  ];

  return (
    <>
      <video autoPlay muted loop className="video">
        <source src="./tech2-vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay"></div>
      
      <div className='landing-page-main'>
        <Link to={`/home`} className='row align-center justify-center english-btn back-link' style={{gap: '5px', marginTop: '5px'}}><i className="fa-solid fa-arrow-left fa-2x white"></i>&nbsp;BACK</Link>
        <div>
          <h4 className='light-grey animate__animated animate__fadeInLeft section-title'>FAQs</h4>
          <div className='rule'></div>
        </div>

        <div className='component-body column align-center faqs-component-body' style={{ marginTop: '-1px' }}>
          {faqsList.map((faq, index) => (
            <div key={index}>
              <p className='component-text size4 purple bold animate__animated animate__fadeInLeft'>{faq.question}</p>
              <p className='component-text size4 animate__animated animate__fadeInUp'>{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FaqsPage;
