import React from 'react';
import { Link } from 'react-router-dom';

const Notifications = ({ notifications }) => {
  return (
    <>
      <div className='dashboard-component meeting-back'>
        <div className="dashboard-overlay"></div>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>Messages</p>
        </div>

        <div style={{marginTop: "40px", backgroundColor: "#f3f3fb", paddingBottom: "40px"}}>
          {notifications.map(notification => (
              <p key={notification.id} className='info-item' style={{marginTop: "0px"}}>
                <span className='purple bold size5'>{notification.title}</span><br />
                <span className='size4'>{notification.content}</span>            
              </p>
          ))}
        </div>
      </div>
    </>
  );
};

export default Notifications;
