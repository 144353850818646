import React, { useState } from 'react';
import { db } from '../config/fbConfig';
import { collection, addDoc } from 'firebase/firestore';

const Support = ({ userData, user }) => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'messages'), {
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: userData.phone,
        github: userData.github,
        email: user.email,
        message,
        timestamp: new Date()
      });
      setMessage('');
      setSuccessMessage('Message sent. We will be in touch shortly.');
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <div className="dashboard-overlay"></div>
      <div className='dashboard-component support-back'>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>Support</p>
        </div>
        <div>
          <p className='info-item'>
            <span className='purple bold size5'>Get in touch</span><br />
            <span className='size4'>Please use the form below to contact our support staff, and we will get back to you in no more than 24 hours.</span>            
          </p>

          <form onSubmit={handleSubmit} className='support-form'>
            <input type='hidden' id='support-first-name' name='support-first-name' value={userData.firstName} />
            <input type='hidden' id='support-last-name' name='support-last-name' value={userData.lastName} />
            <input type='hidden' id='support-phone' name='support-phone' value={userData.phone} />
            <input type='hidden' id='support-email' name='support-email' value={user.email} />
            <input type='hidden' id='support-github' name='support-github' value={userData.github} />
            <textarea 
              placeholder='Type your message' 
              className='support-textarea' 
              value={message} 
              onChange={(e) => setMessage(e.target.value)} 
              required
            />
            <button type="submit" className='dashboard-component-btn'>
              Send
            </button>
          </form>
          {successMessage && <div className="success-message green size4">{successMessage}</div>}
          {error && <div className="error red size4">{error}</div>}
        </div>
      </div>
    </>
  );
};

export default Support;
