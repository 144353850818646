import React, { useState, useEffect } from 'react';
import '../styles/Join.css';
import WYCircle from '../images/white_and_yellow_circle.png';
import BPCircle from '../images/blue_and_purple_circle.png';
import JoinPic from '../images/join-pic.png';
import StarBlue from '../images/star_blue.png';
import StarPurple from '../images/star_purple.png';
import PurpleCheck from '../images/purple-check.png';
import Navbar1 from '../components/Navbar1';
import { auth } from '../config/fbConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import PasswordResetForm from '../components/ChangePassword';

const Join = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPopup1, setShowPopup1] = useState(false);

  const openPopup1 = () => {
    setShowPopup1(true);
  };

  const closePopup1 = () => {
    setShowPopup1(false);
  };

  useEffect(() => {
    if (showPopup1) {
      document.body.classList.add('body-no-scroll');
    } else {
      document.body.classList.remove('body-no-scroll');
    }

    return () => {
      document.body.classList.remove('body-no-scroll');
    };
  }, [showPopup1]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setError('Invalid Email or Password');
    }
  };

  return (
    <>
      <Navbar1 />

      <div className={`join-form-container single-view-page`} style={{overflowY: 'hidden'}}>
        <img src={WYCircle} alt='' className='wyc'/>
        <img src={BPCircle} alt='' className='bpc'/>
        <img src={StarBlue} alt='' className='star-blue'/>
        <img src={StarPurple} alt='' className='star-purple'/>

        <div className='join-page-statement-div'>
        <p className='join-page-statement'>This login form is only for admitted <span>SHEros<img src={PurpleCheck} alt='' className='purple-check'/></span></p>
        </div>

        <div className='join-pic-div'>      
          <img src={JoinPic} alt='' className='join-pic'/>      
        </div>  

        <div className='form'>  
          <form onSubmit={handleLogin}>
            <div>
              <label>
                <p className='input-label first-label'>Email</p>
              </label>
              <input
                type="email"
                value={email}
                className='input-field'
                placeholder='example@xyz.com'
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>
                <p className='input-label first-label'>Password</p>
              </label>
              <input
                type="password"
                value={password}
                className='input-field'
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className='form-btn'>Login</button>
          </form>
          {error && <div className="error red">{error}</div>}
          <button className='form-btn' style={{backgroundColor: '#8e44ad'}} onClick={() => openPopup1()}>Change Password</button>
        </div>        
      </div>

      {showPopup1 && (
        <div className="popup popup1">
          <div className="popup-content popup-content1">
            <i className="fa-regular fa-circle-xmark popup-close popup-close1" onClick={closePopup1}></i>
            <PasswordResetForm />
          </div>
        </div>
      )}          
    </>
  );
};

export default Join;
