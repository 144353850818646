import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar1.css';
import Logo from '../images/elevateher_logo_black.png';

const Navbar1 = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuOnClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('overlay', 'no-scroll');
    } else {
      document.body.classList.remove('overlay', 'no-scroll');
    }

    return () => {
      document.body.classList.remove('overlay', 'no-scroll');
    };
  }, [!isMenuOpen]);

  return (
    <>
    <div className={`nav-back ${isMenuOpen ? 'change' : ''}`}></div>

    <div id="menu" className={`navbar ${isMenuOpen ? 'change' : ''}`}>

      <div className='row align-center' style={{ gap: '6px', display: 'inline-flex' }}>
        <div className={`bars desk-none ${isMenuOpen ? 'change' : ''}`} id="menu-bar" onClick={menuOnClick}>
          <div id="bar1" className={`bar ${isMenuOpen ? 'change' : ''}`}></div>
          <div id="bar2" className={`bar ${isMenuOpen ? 'change' : ''}`}></div>
          <div id="bar3" className={`bar ${isMenuOpen ? 'change' : ''}`}></div>
        </div>

        <div className='mob-none nav-links-desk'>
          <Link to='/home' className='bold purple'>Home</Link>
          <Link to='/about' className='bold purple'>About</Link>
          {/* <Link to='/admission' className='bold purple'>Admission</Link> */}
          <Link to='https://forms.gle/kQLJeskDq1BVDLu19' target='_blank' className='nav-join'>Apply</Link>
        </div>

        <Link to='/home'><img src={Logo} alt='logo' className={`nav-logo ${isMenuOpen ? 'change' : ''}`}/></Link>
      </div>

      <nav className={`nav ${isMenuOpen ? 'change' : ''}`}>
        <ul>
          <li><Link to='/home'>Home</Link></li>
          <li><Link to='/about'>About</Link></li>
          {/* <li><Link to='/admission'>Admission</Link></li> */}
          <li><Link to='https://forms.gle/kQLJeskDq1BVDLu19' target='_blank'>Apply</Link></li>
        </ul>
      </nav>
      <div className={`menu-bg ${isMenuOpen ? 'change-bg' : ''}`}></div>
      {isMenuOpen && <div className="overlay1"></div>}
    </div>
    </>
  );
};

export default Navbar1;