import React from 'react';
import { Link } from 'react-router-dom';

const Github = ({userData}) => {

  return (
    <>
      <div className='dashboard-component github-back'>
      <div className="dashboard-overlay"></div>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>GitHub</p>
        </div>

        <div>
          <p className='info-item' style={{marginBottom: "40px"}}>
            <span className='purple bold size5'>Profile Link</span><br />
            <span className='size4'>Please, click on the link below to go to {userData.firstName}'s Profile on GitHub.</span>            
          </p>

            <Link to={userData.github} className='dashboard-component-btn' target='_blank'>
              GitHub Profile
            </Link>
        </div>
      </div>
    </>
  );
};

export default Github;
