import React from 'react';
import { Link } from 'react-router-dom';
import FacebookLogo from '../images/facebook.png';
import InstagramLogo from '../images/instagram.png';
import XLogo from '../images/x.png';
import LinkedInLogo from '../images/linkedin.png';
import '../styles/Contact.css';

const Contact = () => {

  return (
    <>
      <video autoPlay muted loop className="video">
        <source src="./tech2-vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay"></div>

      <div className='contact-page single-view-page'>
        <div className='landing-page-main'>
          <Link to={`/home`} className='row align-center justify-center english-btn back-link' style={{gap: '5px', marginTop: '5px'}}><i className="fa-solid fa-arrow-left fa-2x white"></i>&nbsp;BACK</Link>
          <div>
            <h4 className='light-grey animate__animated animate__fadeInLeft section-title'>Contact Us</h4>
            <div className='rule'></div>
          </div>

          <div className='register animate__animated animate__zoomIn' style={{ textAlign: 'center', marginTop: '30px', paddingBottom: '5px', paddingTop: '0.5px' }}>
            {/* <p className={`bold blue size1 signin-vision`}>Contact Us</p> */}
            <form action="https://formspree.io/f/xlevqnng" method="post" id="form">
              <label>
                <p className='input-label first-label'>Full Name</p>
              </label>
                <input
                  id='fullName'
                  type="text"
                  name="Full Name"
                  className='input-field register-input'
                  placeholder='eg. Jane Doe'
                  required
                />
              
              <br />
              {/* <label>
                <p className='input-label'>Phone Number</p>
              </label>
                <input
                  type="tel"
                  id='phoneNumber'
                  name="Phone Number"
                  placeholder='eg. 080. . .'
                  className='input-field register-input'
                  required
                />
              <br /> */}
              <label>
                <p className='input-label'>Email</p>
              </label>
                <input
                  type="email"
                  id="emailid" 
                  placeholder="example@xyz.com" 
                  name="Email"
                  className='input-field register-input'
                  required
                />
              <br />
              <label>
                <p className='input-label'>Message</p>
              </label>
                <textarea
                  id="message" 
                  placeholder="Type your message here" 
                  name="Message"
                  className='input-field register-input'
                  maxLength="500"
                  rows='6' 
                  cols="10"
                  required
                />
              <br />
              <button type="submit" className='gen-form-btn register-btn'>
                Contact Us
              </button>
            </form>
          </div>

          <p className='size4' style={{ textAlign: 'center' }}>You can also check us out on our socials</p>
            <div className='row align-center justify-center socials-div animate__animated animate__fadeInUp'>
              <Link to='' className='footer-social'><img src={FacebookLogo} className='social' /></Link>
              <Link to='' className='footer-social'><img src={InstagramLogo} className='social' /></Link>
              <Link to='' className='footer-social'><img src={LinkedInLogo} className='social' /></Link>
              <Link to='' className='footer-social'><img src={XLogo} className='social' /></Link>
            </div>
        </div>
        <p className='copyrights'>Copyright © {new Date().getFullYear()} ElevateHER Innovation Space Ltd | All Rights Reserved</p>
      </div>
    </>
  );
};

export default Contact;
