// PasswordResetForm.js
import React, { useState } from 'react';
import { auth } from '../config/fbConfig';
import { sendPasswordResetEmail } from 'firebase/auth';

const PasswordResetForm = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Password reset email sent. Check your inbox.');
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="password-reset-form form" style={{marginTop: '10vh'}}>
      <h3 className='blue'>Change Password</h3>
      <p className='size3'>Please, provide the correct email address the password change link will be sent to. Note that you have to be a registered student to successfully change your password.</p>
      <form onSubmit={handlePasswordReset}>
        <div>
          <label>
            <p className='input-label first-label'>Email</p>
          </label>
          <input
            type="email"
            value={email}
            className='input-field first-label'
            placeholder='example@xyz.com'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button className='form-btn' style={{backgroundColor: '#8e44ad'}} type="submit">Send</button>
      </form>
      {successMessage && <div className="success-message green">{successMessage}</div>}
      {error && <div className="error red">{error}</div>}
    </div>
  );
};

export default PasswordResetForm;
