import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth, db } from '../config/fbConfig';
import { getDoc, doc, collection, getDocs } from 'firebase/firestore';
import Join from '../pages/Join';
import Github from '../components/Github';
import Info from '../components/Info';
import Progress from '../components/Progress';
import Meeting from '../components/Meeting';
import Handbook from '../components/Handbook';
// import Faqs from '../components/Faqs';
import Support from '../components/Support';
import Notifications from '../components/Notifications';
import '../styles/DashboardLogout.css';

const Authentication = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [activeComponent, setActiveComponent] = useState('info');
  const [meetings, setMeetings] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  // Set up authentication state listener
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userDocRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(userDocRef);

          if (docSnap.exists()) {
            setUserData(docSnap.data());
          } else {
            console.log('User data does not exist');
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [user]);

  // Fetch meetings data
  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const meetingsCollectionRef = collection(db, 'meetings');
        const meetingsSnapshot = await getDocs(meetingsCollectionRef);

        const meetingsList = meetingsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setMeetings(meetingsList);
      } catch (error) {
        console.error('Error fetching meetings:', error);
      }
    };

    fetchMeetings();
  }, []);

  // Fetch notifications data and set count
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const notificationsCollectionRef = collection(db, 'notifications');
        const notificationsSnapshot = await getDocs(notificationsCollectionRef);

        const notificationsList = notificationsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setNotifications(notificationsList);
        setNotificationCount(notificationsSnapshot.docs.length);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  // Handle user logout
  const handleLogout = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <div>
      {!user ? (
        <Join />
      ) : (
        <div className="dashbord-back-main">
          <div className='dashboard'>
            {userData ? (
              <div className='row logout-div'>
                <p className='size4 bold purple'>Hello, {userData.firstName}</p>
                <button className='logout-btn align-center' onClick={handleLogout}>
                  <span className='purple'>LOG OUT</span><i className="fa-solid fa-right-from-bracket fa-2x purple"></i>
                </button>
              </div>
            ) : (
              <div>You are suspended. Please, contact support.</div>
            )}
            <nav className="side-bar">
              <div className='column align-center'>
                <Link to="/home" className='row align-center justify-center dashboard-back-btn yellow' style={{gap: '5px'}}>
                  <i className="fa-solid fa-arrow-left fa-2x yellow"></i>&nbsp;BACK
                </Link>
                <h1 className='yellow dashboard-heading'>Dashboard</h1>
              </div>
              <ul className='column'>
                <li>
                  <button onClick={() => handleButtonClick('info')} className={`nav-link ${activeComponent === 'info' ? 'active' : ''}`}>
                    Info
                  </button>
                </li>
                <li>
                  <button onClick={() => handleButtonClick('progress')} className={`nav-link ${activeComponent === 'progress' ? 'active' : ''}`}>
                    Progress
                  </button>
                </li>
                <li>
                  <button onClick={() => handleButtonClick('github')} className={`nav-link ${activeComponent === 'github' ? 'active' : ''}`}>
                    GitHub
                  </button>
                </li>
                <li>
                  <button onClick={() => handleButtonClick('meeting')} className={`nav-link ${activeComponent === 'meeting' ? 'active' : ''}`}>
                    Meeting
                  </button>
                </li>
                <li>
                  <button onClick={() => handleButtonClick('handbook')} className={`nav-link ${activeComponent === 'handbook' ? 'active' : ''}`}>
                    Handbook
                  </button>
                </li>
                {/* <li>
                  <button onClick={() => handleButtonClick('faqs')} className={`nav-link ${activeComponent === 'faqs' ? 'active' : ''}`}>
                    FAQs
                  </button>
                </li> */}
                <li>
                  <button onClick={() => handleButtonClick('support')} className={`nav-link ${activeComponent === 'support' ? 'active' : ''}`}>
                    Support
                  </button>
                </li>
                <li>
                  <button onClick={() => handleButtonClick('notifications')} className={`nav-link ${activeComponent === 'notifications' ? 'active' : ''}`}>
                    Admin <br />Messages ({notificationCount})
                  </button>
                </li>
              </ul>
            </nav>
            <div className='chat-component'>
              {activeComponent === 'info' && <Info userData={userData} />}
              {activeComponent === 'progress' && <Progress userData={userData} />}
              {activeComponent === 'github' && <Github userData={userData} />}
              {activeComponent === 'meeting' && <Meeting meetings={meetings} />}
              {activeComponent === 'handbook' && <Handbook />}
              {/* {activeComponent === 'faqs' && <Faqs />} */}
              {activeComponent === 'support' && <Support userData={userData} user={user} />}
              {activeComponent === 'notifications' && <Notifications notifications={notifications} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Authentication;