import React from 'react';
import TechHubImg from '../images/tech-hub.png';
import BusinessImg from '../images/business.png';
import '../styles/Programs.css';

const Programs = () => {

  return (
    <>
      <div className='column align-center tech-career'>
        <div className='column align-center program-div'>
          <p className='bold size3 program-title'>PHASE ONE</p>
          <div className='programs-img-div'>
            <img src={TechHubImg} className='programs-img' />
          </div>          
          <div className='program-desc-div'>
            <p className='blue bold program-heading' style={{ textAlign: 'center' }}>Technical Skills</p>
            <p className='size4 program-text' style={{ textAlign: 'center' }}>Embark on a transformative journey with our comprehensive technical program designed to equip you with the skills and knowledge essential for a successful career in full-stack development. Delve into the latest technologies, frameworks, and programming languages through hands-on projects, interactive workshops, and expert-led sessions.</p>
          </div>
        </div>

        <div className='column align-center program-div'>
          <p className='bold size3 program-title'>PHASE TWO</p>
          <div className='programs-img-div'>
            <img src={BusinessImg} className='programs-img programs-img2' />
          </div>
          <div className='program-desc-div'>
            <p className='bold purple program-heading' style={{ textAlign: 'center' }}>Professional Skills</p>
            <p className='size4 program-text' style={{ textAlign: 'center' }}>Once you've mastered the technical essentials, shift gears to our career improvement part, where we focus on empowering you for success in the professional world. Explore personalized career development resources, including resume building, interview preparation, and networking strategies. Our dedicated career coaches and industry experts will guide you through navigating the tech job market, offering insights and strategies to enhance your career prospects.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Programs;
