import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/PrivacyPolicy.css';

const PrivacyPolicy = () => {

  return (
    <>
      <video autoPlay muted loop className="video">
        <source src="./tech2-vid.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay"></div>
      
      <div className='landing-page-main'>
        <Link to={`/home`} className='row align-center justify-center english-btn back-link' style={{gap: '5px', marginTop: '5px'}}><i className="fa-solid fa-arrow-left fa-2x white"></i>&nbsp;BACK</Link>
        <div>
          <h4 className='light-grey animate__animated animate__fadeInLeft section-title'>Privacy Policy</h4>
          <div className='rule'></div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
