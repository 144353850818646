import React from 'react';
import { Link } from 'react-router-dom';
import StarDots from '../images/dots_and_start_1.png';
import DotsStar from '../images/dots_and_start_2.png';
import AKPic from '../images/ak.png';
import MJPic from '../images/mary_jane.png';
import '../styles/About.css';
import Footer from '../components/Footer';
import Navbar1 from '../components/Navbar1';
import ScrollToTopButton from '../components/ScrollToTopButton';

const About = () => {

  return (
    <>
      <Navbar1 />

      <div className='about-page'>
        <img src={DotsStar} alt='' className='dots-star' />
        <div className='about-contents'>
          <div>
            <p className='size4'>At <span className='about-landing-heading bold size3'>Elevate<span className='blue her'>HER</span></span>, we believe in the power of diversity and inclusivity in the world of technology. Our mission is to empower women by providing them with the skills and knowledge needed to thrive in the dynamic field of full-stack development. We are committed to breaking down barriers and creating a supportive community where women can learn, grow, and excel in the tech industry.</p>
            <p className='size4'>Through our full-stack development program, we aim to bridge the gender gap in the tech industry and foster an environment where innovation knows no boundaries.</p>
          </div>

          <div className='about-pic-div one'>
            <div className='about-pic-container'>
              <img src={AKPic} alt='Aisha Kwaku' className='about-pic' />
            </div>
            <div className='about-pic-content'>
              <p className='purple size5 bold'>"I envision a world where every woman has equal opportunity to learn and thrive in a professional work environment"</p>
              <p className='size2 bold'>Aisha Kwaku</p>
              <p className='size5 blue bold'>Founder, EHIS</p>
              <p className='size5'>Technoprenuer, Tech Enthusiast</p>
            </div>
          </div>
          <div className='about-pic-div two'>
            <div className='about-pic-container'>
              <img src={MJPic} alt='Aisha Kwaku' className='about-pic' />
            </div>
            <div className='about-pic-content'>
              <p className='purple size5 bold'>"Even when you fit in be unique"</p>
              <p className='size2 bold'>Mary-Jane Sule Ph.D.</p>
              <p className='size5 blue bold'>Co-Founder, EHIS</p>
              <p className='size5'>Head of Partnerships</p>
            </div>
          </div>

          <div>
            <p className='size4'>Whether you're a beginner taking your first steps into the world of coding or an experienced developer looking to expand your skill set, <span className='about-landing-heading bold size3'>Elevate<span className='blue her'>HER</span></span> has something to offer. Join us on this exciting journey, and let's reshape the future of technology together.</p>
          </div>
          <div className='column align-center justify-center'>
            <Link to="https://forms.gle/kQLJeskDq1BVDLu19" target='_blank' className='about-join-us'>Join Us</Link>
          </div>
        </div>
        <img src={StarDots} alt='' className='star-dots' />
      </div>

      <ScrollToTopButton />
      <Footer />
    </>
  );
};

export default About;
