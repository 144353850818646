import React from 'react';
import '../styles/Info.css';

const Info = ({ userData }) => {
  const totalModules = 6;
  const modulesCompleted = (userData.module - 1) || 0;
  const percentageCompleted = ((modulesCompleted / totalModules) * 100).toFixed(0);

  return (
    <>
      <div className='dashboard-component info-back'>
        <div className='dashboard-component-heading'>
          <p className='dashboard-component-heading bold'>Info</p>
        </div>
        <div>
          <p className='info-item'>
            <span className='purple bold size5'>Name</span><br />
            <span className='size4'>{userData.firstName} {userData.lastName}</span>
          </p>
          <p className='info-item'>
            <span className='purple bold size5'>Email</span><br />
            <span className='size4'>{userData.email}</span>
          </p>
          <p className='info-item'>
            <span className='purple bold size5'>Cohort</span><br />
            <span className='size4'>{userData.cohort}</span>
          </p>
          <p className='info-item'>
            <span className='purple bold size5'>Present Module</span><br />
            <span className='size4'>{userData.module}</span>
          </p>
          <p className='info-item'>
            <span className='purple bold size5'>Modules Completed</span><br />
            <span className='size4'>{modulesCompleted} / {totalModules}</span>
          </p>
          <p className='info-item'>
            <span className='purple bold size5'>Percentage Complete</span><br />
            <span className='size4'>{percentageCompleted}%</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Info;
