import React from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';
import FacebookLogo from '../images/facebook.png';
import InstagramLogo from '../images/instagram.png';
import XLogo from '../images/x.png';
import LinkedInLogo from '../images/linkedin.png';
import LogoWhite from '../images/elevateher_logo_white.png';

const Footer = () => {

  return (
    <>
      <div className='footer'>
        <div className='footer-div'>
          <div className='footer-links column align-center'>
            <h5 className='footer-subheading'>Company</h5>
            <Link to='/about'>About Us</Link>
            {/* <Link to='/admission'>Admission</Link> */}
            <Link to=''>Privacy Policy</Link>
            <Link to=''>Terms & Conditions</Link>
          </div>

          <div className='footer-links column align-center'>
            <h5 className='footer-subheading'>Resources</h5>
            <Link to='https://forms.gle/kQLJeskDq1BVDLu19' target='_blank'>Apply</Link>
            <Link to=''>FAQs</Link>
          </div>

          <div>
            <div className='column align-center'>
              <img src={LogoWhite} alt='' className='footer-logo'/>
            </div>

            <div className='footer-socials column align-center'>
              <p className='white size4 footer-text' style={{ textAlign: 'center' }}>Join us as we embark on our journey to empower millions of women across the globe</p>
              <div className='row socials-div'>
                <Link to='https://web.facebook.com/people/Elevateher-Innovation-Space/61560910463423/?name=xhp_nt__fb__action__open_user' className='footer-social' target='__blank'><img src={FacebookLogo} className='social' /></Link>
                <Link to='https://www.instagram.com/elevateher_innovation_space_/' className='footer-social' target='__blank'><img src={InstagramLogo} className='social' /></Link>
                <Link to='https://www.linkedin.com/company/elevateher-innovation-space' className='footer-social' target='__blank'><img src={LinkedInLogo} className='social' /></Link>
                <Link to='https://x.com/elevateher__/' className='footer-social' target='__blank'><img src={XLogo} className='social' /></Link>
              </div>
            </div>
          </div>
        </div>
        <p className='copyrights'>Copyright © {new Date().getFullYear()} ElevateHER Innovation Space Ltd | All Rights Reserved</p>
      </div>
    </>
  );
};

export default Footer;
