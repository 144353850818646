import React from 'react';
import '../styles/Dashboard.css';
import Authentication from '../firebase/Authentication';

const Dashboard = () => {

  return (
    <>
      <Authentication />      
    </>  
  );
};

export default Dashboard;
