import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Signin.css';

const Signin = () => {

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    lga: '',
    phoneNumber: '',
  });

  const [isRegisterMode, setIsRegisterMode] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);

    try {
      if (isRegisterMode) {
        // Register API call
        const response = await fetch('http://localhost:3000/api/v1/facebook_reviews', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          // Registration successful, redirect or perform necessary actions
          console.log('Registration successful');
        } else {
          // Handle registration error
          console.error('Registration failed');
        }
      } else {
        // Sign-in API call
        const response = await fetch('http://localhost:3000/api/v1/elevate_users/sign_in', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
  
        if (response.ok) {
          // Sign-in successful, redirect or perform necessary actions
          console.log('Sign-in successful');
        } else {
          // Handle sign-in error
          console.error('Sign-in failed');
        }
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleToggleMode = () => {
    setIsRegisterMode(!isRegisterMode);
  };

  return (
    <>
      <div className={`landing-page signin-page ${isRegisterMode ? 'register-mode' : ''}`}>
        <div className='landing'>
        <Link to={`/home`} className='row align-center justify-center english-btn back-link' style={{gap: '5px'}}><i className="fa-solid fa-arrow-left fa-2x white"></i>&nbsp;BACK</Link>
          <div className={`${isRegisterMode ? 'register' : 'signin'} ${isRegisterMode ? 'register flipped' : 'signin'}`}>
            <p className={`bold purple size1 ${isRegisterMode ? 'signin-mission' : 'signin-vision'}`}>{isRegisterMode ? 'Register' : 'Sign In'}</p>
            {isRegisterMode ? 
            <>
              <form className='register-form' onSubmit={handleSubmit}>
                <label>
                  <p className='input-label first-label'>First Name</p>
                </label>
                  <input
                    type="text"
                    name="firstName"
                    className='input-field register-input'
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                <br />

                <label>
                  <p className='input-label'>Last Name</p>
                </label>
                  <input
                    type="text"
                    name="lastName"
                    className='input-field register-input'
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                <br />

                <label>
                  <p className='input-label'>Address</p>
                </label>
                  <input
                    type="text"
                    name="address"
                    className='input-field register-input'
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                <br />

                <label>
                  <p className='input-label'>LGA</p>
                </label>
                  <input
                    type="text"
                    name="lga"
                    className='input-field register-input'
                    value={formData.lga}
                    onChange={handleChange}
                    required
                  />
                <br />

                <label>
                  <p className='input-label'>Phone Number</p>
                </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder='eg. 080. . .'
                    className='input-field register-input'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                <br />

                <button type="submit" className='gen-form-btn register-btn'>
                  Register
                </button>

                {/* <Link to="/dashboard" className='gen-form-btn register-btn'>
                  Register
                </Link> */}
              </form>
            </>
            : 
            <>
              <form onSubmit={handleSubmit}>
                <label>
                  <p className='input-label first-label'>First Name</p>
                </label>
                  <input
                    type="text"
                    name="firstName"
                    className='input-field signin-input'
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                
                <br />
                <label>
                  <p className='input-label'>Phone Number</p>
                </label>
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder='eg. 080. . .'
                    className='input-field signin-input'
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                <br />
                <button type="submit" className={`gen-form-btn ${isRegisterMode ? 'register-btn' : 'signin-btn'}`}>
                  Sign In
                </button>
                {/* <Link to="/dashboard" className='gen-form-btn signin-btn'>
                  Sign In
                </Link> */}
              </form>
            </>}
            </div>
            <p className='signin-text'>
            {isRegisterMode
              ? 'If you already have an account, please '
              : 'If you are new on this platform, please '}<br />
            <button className={`text-button ${isRegisterMode ? 'register-text-btn' : 'signin-text-btn'}`} onClick={handleToggleMode}>
              {isRegisterMode ? 'Sign In' : 'Register'}
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export default Signin;
