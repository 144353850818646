import React from 'react';
import '../styles/VisionMission.css';

const VisionMission = () => {

  return (
    <>
      <div className='vision-mission'>
        <div>
          <div className='column align-center'>
            <h3 className='vision'>Vision</h3>
          </div>

          <div>
            <p className='vision-text size4' style={{ textAlign: 'center' }}><i>We envision a world where every woman has the opportunity to pursue a career in technology without limitations</i></p>
          </div>
        </div>

        <div>
          <div className='column align-center'>
            <h3 className='mission'>Mission</h3>
          </div>

          <div>
            <p className='mission-text size4' style={{ textAlign: 'center' }}><i>Our mission is to build a community of skilled women in tech thriving in their roles and building solutions for Africa and beyond</i></p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VisionMission;
